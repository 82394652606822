.code-application .setsetting2 {
  min-width: 330px; }
  .code-application .setsetting2 td {
    border: 1px solid #ddd;
    text-align: center;
    padding: 10px; }

.code-application .tc-15-dropdown {
  width: 330px; }

.code-application .file-package {
  display: inline-block; }
  .code-application .file-package select,
  .code-application .file-package input {
    width: 160px; }
  .code-application .file-package select {
    min-width: 160px;
    margin-right: 10px; }
  .code-application .file-package span {
    font-size: 12px;
    vertical-align: middle;
    padding-left: 5px; }

.code-application .tc-15-input-num .minus,
.code-application .tc-15-input-num .plus {
  height: 30px; }

.code-application .tc-15-input-num .num {
  padding: 0;
  width: 102px;
  height: 28px;
  line-height: 28px;
  float: left;
  border: 1px solid #ddd;
  text-align: center;
  position: relative;
  margin: 0;
  background: #fff;
  font-family: "microsoft yahei";
  font-size: 12px; }
