.SystemLogin {
  height: 100vh;
  min-height: 400px;
  overflow-y: auto;
  background: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .SystemLogin .main-block {
    position: relative;
    background: #fff;
    width: 600px;
    height: 250px;
    border: 1px solid #e2e2e2;
    text-align: center; }
    .SystemLogin .main-block .img {
      width: 300px;
      position: absolute;
      top: -70%;
      left: 50%;
      transform: translateX(-50%); }
    .SystemLogin .main-block .form {
      margin-left: 20%;
      margin-top: 50px;
      text-align: left; }
    .SystemLogin .main-block .title {
      position: absolute;
      font-size: 20px;
      color: #000;
      left: 50%;
      transform: translateX(-50%);
      top: -60px;
      font-weight: bold; }

@media only screen and (max-height: 650px) {
  .SystemLogin .media {
    width: 100%;
    height: 150px; } }
